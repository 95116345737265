/* ========================================================================== */
/*                            VARIABLES DE COULEURS                           */
/* ========================================================================== */

$dominante: #47b25e;

$dark: #181818;
$greyDark: #212121;
$greyMiddle: #696969;
$greyLight: #eeeeee;

$red: #832525;