/* ========================================================================== */
/*                                   CONTACT                                  */
/* ========================================================================== */

.formContact {
  padding-top: 30px;
}

.contactDetails{
  margin-top: 0px;
  margin-bottom: 30px;
  font-size: 0.9em;
  
}