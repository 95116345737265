@import "../../variables.scss";

/* ========================================================================== */
/*                                     CV                                     */
/* ========================================================================== */

.colored {
  color: $dominante !important;
}

#introCv {
  margin-top: 100px;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}

#degrees {
  text-align: center;
  padding-top: 10px;
}

.skillItem {
  position: relative;
  margin-top: -40px;

  width: 50px;
  height: 80px;
  margin: 0 auto;
  margin-bottom: 20px;

  .contentSkill {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: 0 auto;

    .detailCodeSkillContainer {
      position: absolute;
      height: 50px;
      width: 100%;
      top: 50px;
      text-align: center;
      margin: 0 auto;
      overflow: hidden;
    }

    .skillImgOverlay {
      z-index: initial !important;
      .skillImg {
        top: 0px;
        z-index: -1 !important;
        transition: 500ms;
        position: relative;
        margin: 0 auto;
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }
    }
  }
}


.skillImgOverlay:hover {
  cursor: pointer;
  .skillImg {
    top: -10px !important;
    width: 65px !important;
    height: 65px !important;
    transition: 500ms;
    transform: rotateY(360deg);
  }
}

.contentSkill:hover {
  .detailCodeSkill {
    transition: 0.3s ease-in-out;
    color: #ffffff !important;
  }
}


.apprentissage {
  .skillImg {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}

.modalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, 0.9);
  cursor: pointer;
  overflow: scroll;

  .modal {
    position: absolute;
    padding-top: 60px;
    left: 0px;
    text-align: center;
    display: block;
    width: 100%;
    min-height: 100%;
    background-color: transparent;
    padding-bottom: 80px;
    .modalInfos {
      padding-top: 30px;
      height: 140px;
      width: 100%;
      .modalImg {
        border-radius: 10px;
        width: 80px;
        margin-right: 30px;
      }
      .modalSkillTitle {
        font-size: 20pt;
        vertical-align: 85%;
        display: inline;
      }
    }
  }
}

.detailCodeSkill {
  transition: 0.3s ease-in-out;
  color: $greyMiddle;
  top: -50px;
  text-align: center;
  position: absolute;
  width: 100%;
}

.skillBlock:hover {
  .detailCodeSkill {
    transition: 0.3s ease-in-out;
    top: 10px;
  }
}

.skillBlock {
  text-align: center;
  position: relative;
  margin-left: 0px;
  margin-right: 25px;
  margin-top: 50px;
  margin-bottom: 0px;
  max-width: 100vw;
}

@media (min-width: 992px) {
  .skillBlock {
    margin-left: 20vw;
    margin-right: 20vw;

  }
}

.degreeImg {
  border-radius: 5px;
}

hr {
  margin-top: 30px;
  border-color: #373535;
}

.categorySkill {
  text-align: left;
  top: -38px;
  margin-top: 20px;
  margin-left: 40px;
  font-size: 0.8em;
  color: $greyMiddle;
}

.school {
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 20px;
  display: inline-block;
  img {
    width: 50px;
    height: 50px;
  }
}

#introCv {
  padding-left: 20px;
  padding-right: 20px;
}

.degreeDetail {
  vertical-align: 100%;
  margin-left: 20px;
  font-size: 1em;
  font-weight: 500;
  color: $dominante;
}

.parcoursBloc {
  padding-top: 130px;
  padding-bottom: 0px;
  overflow: hidden;
}

#parcours {
  display: block;
  text-align: center;
  width: 100%;
  height: 5px;
}

#endParcours {
  position: absolute;
  top: 393px;
  left: 10%;
  font-size: 1.5em;
  color: rgba(255, 255, 255, 1);
}

.point {
  transition: 0.3s;
  height: 15px;
  width: 15px;
  margin-top: 55px;
  border-radius: 25px;
  background-color: $dominante;
  position: absolute;
}

#parcoursContainer {
  margin-left: 10%;
  position: absolute;
  margin-top: -60px;
  height: 2px;
  background-color: #c7c7c7;
  .dateViewContainer {
    display: none;
    transition: 0.3s;
    cursor: pointer;
    position: absolute;
    text-align: left;
    margin-top: -60px;
  }
  .dateView {
    transition: 0.3s;
    text-align: left;
    position: absolute;
    transform: rotate(-90deg);
    margin-top: 20px;
    margin-left: -9px;
    color: rgba(255, 255, 255, 0);
  }
}

.titleViewContainer {
  position: absolute !important;
  display: inline-block;
  top: 100px;
  width: 80vw;
  .titleView {
    text-align: center;
    width: 200px;
    margin: 0 auto !important;
    color: rgba(255, 255, 255, 0);
  }
}

.lastParcours {
  .dateView {
    transition: 0.3s;
    transform: rotate(-45deg);
    color: rgba(255, 255, 255, 1);
  }
  .point {
    transition: 0.3s;
    height: 20px;
    width: 20px;
    margin-top: 52.5px;
    margin-left: -2.5px;
    background-color: #ffffff;
  }
  .titleView {
    transition: 0.3s;
    margin-top: -110px !important;
    top: -50px;
    float: right;
    color: rgba(255, 255, 255, 1);
  }
}

.dateViewContainer:hover {
  transition: 0.3s;
  .dateView {
    transition: 0.3s;
    transform: rotate(-45deg) !important;
    margin-left: 10px !important;
    margin-top: 28px !important;
    color: rgba(255, 255, 255, 1) !important;
  }
  .point {
    transition: 0.3s;
    height: 20px;
    width: 20px;
    margin-top: 52.5px;
    margin-left: -2.5px;
    background-color: #ffffff;
  }
  .titleView {
    transition: 0.3s;
    color: rgba(255, 255, 255, 1);
  }
}

.photoProfile {
  width: 150px;
  border-radius: 10px;
  margin-top: -40px;
}

.photoProfileContainer {
  margin-top: -30px;
}

.ageDetail {
  color: #373535;
  font-size: 8pt;
  position: relative;
  top: 15px;
  left: -53px;
}

.nameDetail {
  padding-left: 65px;
}

.linkedInIconContainer {
  position: relative;
  top: 37px;
  left: -100px;
}

.linkedInIconLink {
  padding: 10px;
}

.linkedInIcon {
  font-size: 30pt;
}

.gitIconContainer {
  position: relative;
  top: 37px;
  left: -100px;
}

.gitIconLink {
  padding: 10px;
}

.gitIcon {
  font-size: 30pt;
}