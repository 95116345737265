/* ========================================================================== */
/*                                  PORTFOLIO                                 */
/* ========================================================================== */

.folioItemOverlay {
  transition: 0.3s;
  position: absolute;
  width: 100%;
  display: block;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 7;
}

.folioTitle {
  transition: 0.3s;
  color: rgba(19, 19, 19, 1);
  background-color: transparent;
  top: -15px;
  p {
    background-color: rgba(0, 0, 0, 0.4);
    color: #c8c8c8;
  }
}

.folioItem:hover {
  .mini {
    transition: 0.3s;
  }
  .folioItemOverlay {
    height: 25px;
    transition: 0.3s;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .folioTitle {
    transition: 0.3s;
    color: rgba(19, 19, 19, 1);
    top: -12px;
    p {
      background-color: transparent;
      color: #f0f0f0;
    }
  }
}

.folioTitle {
  text-align: center;
  top: -50%;
  transition: 0.3s;
  position: absolute;
  display: block;
  font-size: 1em;
  width: 100%;
  height: auto;
  font-weight: 500;
  background-color: transparent;
  z-index: 8;
}

.folioItem {
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.folioMiniature {
  transition: 0.3s;
  width: 100%;
  display: block;
}

#grid {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 400px) {
  #grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px
  }
}

@media (min-width: 480px) {
  #grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 645px) {
  #grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 900px) {
  #grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1350px) {
  #grid {
    grid-template-columns: repeat(5, 1fr);
  }

  #filterFolio {
    width: calc(100% - 300px);
  }
}

@media (min-width: 1800px) {
  #grid {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (min-width: 3000px) {
  #grid {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media (min-width: 3500px) {
  #grid {
    grid-template-columns: repeat(8, 1fr);
  }
}

@media (min-width: 4000px) {
  #grid {
    grid-template-columns: repeat(9, 1fr);
  }
}