/* --------------------- Importation des variables scss --------------------- */

@import "../../variables.scss";

/* ========================================================================== */
/*                             FOLIO (PROJET SEUL)                            */
/* ========================================================================== */

.folioDetailsTitle {
  font-size: 1.5em;
  font-weight: 600;
  color: $dominante;
}

.folioDetails {
  color: #eeeeee;
  width: 100vw;
  height: 100%;
}

.folioFolderContainer {
  width: 60vw;
}
.folioDetailsDesc {
  margin-top: 30px;
}
.folioFolderImg {
  margin-top: 30px;
  display: block;
  max-height: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 20px;
}

.folioDetailLink {
  transition: 200ms;
  color: #eeeeee;
  font-weight: 600;
}

.folioDetailLink:hover {
  transition: 200ms;
  letter-spacing: 3px;
  font-weight: 600;
}

.videoFolio {
  position: absolute;
  left: 15vw;
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 200px;
  $width: 70vw;
  width: 70vw;
  height: calc($width / 2);
  margin-top: 150px;
}

@media (max-width: 992px) {
  .videoFolio {
    position: absolute;
    left: 0px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 200px;
    $width: 100vw;
    width: 100vw;
    height: calc($width / 2);
    margin-top: 150px;
  }
}

.folioDetailImg {
  cursor: pointer;
}

.folioToolsImg {
  transition: 300ms;
  width: 30px;
  margin: 10px;
}

.folioTools {
  margin-top: 30px;
  vertical-align: 50%;
}

.toolListImg {
  width: 40px;
  margin: 10px;
}
.toolDisable {
  filter: saturate(0);
}
.toolActive {
  filter: saturate(1);
}
