/* --------------------- Importation des variables SCSS --------------------- */

@import "./variables.scss";

/* ========================================================================== */
/*                                  SCROLLBAR                                 */
/* ========================================================================== */

::-webkit-scrollbar-button {
  display: none;
  height: 13px;
  border-radius: 0px;
  background-color: $dark;
}
::-webkit-scrollbar-button:hover {
  background-color: $dark;
}
::-webkit-scrollbar-thumb {
  background-color: $dominante;
}
::-webkit-scrollbar-track {
  background-color: $dark;
}
::-webkit-scrollbar-track:hover {
  background-color: $dark;
}
::-webkit-scrollbar {
  width: 3px;
}
::selection {
  background: $greyLight;
}
::-moz-selection {
  background: $greyLight;
}

/* ----------- Importation de la typo 'Open Sans' de Google Fonts ----------- */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');

/* ========================================================================== */
/*                                   GLOBAL                                   */
/* ========================================================================== */

body {
  background-color: $dark;
  color: $greyLight;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  overflow-x: hidden;
}

a {
  color: $dominante;
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page {
  margin-top: 70px;
  padding-bottom: 80px;
}

.gridItem {
  transition: 0.3s;
}

.arrowBack {
  transition: 200ms;
}

/* ========================================================================== */
/*                              COULEUR DOMINANTE                             */
/* ========================================================================== */

.domiB {
  background-color: $dominante;
}

.domiC {
  color: $dominante;
}

.crochets {
  color: $dominante;
  font-size: 1.2em;
  font-weight: 600;
}

.crochetsInverse {
  color: $greyLight;
}

/* ========================================================================== */
/*                                 NAVIGATION                                 */
/* ========================================================================== */

.nav-wrapper {
  background-color: $dark;
  position: fixed;
  z-index: 20;
  top: 0px;
}

.logo {
  transition: 300ms;
  width: 50px;
  margin-top: 5px;
  position: absolute;
  -webkit-filter: blur(0);
  filter: blur(0);
}

@media (max-width: 992px) {
  .logo {
    margin-top: 10px;
    width: 40px;
    margin-left: 15px;
  }
}

@media (max-width: 400px) {
  .pageLink {
    font-size: 0.8em;
    padding: 0px;
    transition: 200ms;
    background-color: transparent;
  }
}

/* ========================================================================== */
/*                                   FOOTER                                   */
/* ========================================================================== */

.footer {
  padding-top: 10px;
  padding-bottom: 0px;
  color: $greyMiddle;
  text-align: center;
  font-size: 0.8em;
  background-color: $dark;
  width: 100%;
  height: 70px;
  position: fixed;
  display: block;
  bottom: 0px;
  z-index: 10;
}

.footerDescription {
  font-weight: 600;
}

/* ========================================================================== */
/*                                    FORMS                                   */
/* ========================================================================== */

input,
select,
textarea {
  color: $dominante;
}

textarea:focus,
input:focus {
  color: $greyLight;
}

/* ========================================================================== */
/*                             PORTFOLIO (ACCUEIL)                            */
/* ========================================================================== */

.portfolio {
  top: 0px;
  padding-top: 32px;
  overflow: hidden;
}

.folio-list {
  width: 100%;
}

.folioFilter {
  padding-bottom: 10px;
  padding-top: 7px;
  background-color: $dominante;
  position: fixed;
  top: 62px;
  height: 35px;
  width: 100%;
  z-index: 12;
}

@media (max-width: 600px) {
  .folioFilter {
    top: 55px;
  }

  .portfolio {
    padding-top: 25px;
  }
}

.folioFilterLink {
  transition: 300ms;
  padding-left: 10px;
  padding-right: 10px;
  color: $dark;
}

.folioFilterLink:hover {
  transition: 300ms;
  color: $greyLight;
}

/* ========================================================================== */
/*                                 CV (SKILLS)                                */
/* ========================================================================== */

.cv {
  width: 100%;
  max-width: 100%;
}

.skill-list {
  width: 100%;
}

.skill-summary {
  width: 100%;
}

.descriptionSkills {
  color: $dominante;
  margin-left: 10px;
  line-height: 40px;
  font-size: 0.9em;
}

/* ========================================================================== */
/*                                    LINK                                    */
/* ========================================================================== */

.pageLink {
  transition: 200ms;
  background-color: transparent;
}

.pageLink:hover {
  transition: 200ms;
  letter-spacing: 1px;
  font-weight: 600;
  background-color: transparent;
}

.liLink a:before {
  content: "[";
  font-size: 1.4em;
  color: $dominante;
  margin-right: 0px;
  opacity: 0;
  transition: 200ms;
}

.liLink a:after {
  content: "]";
  font-size: 1.4em;
  color: $dominante;
  margin-left: 0px;
  opacity: 0;
  transition: 200ms;
}

.liLink:hover a:after {
  margin-left: 10px;
  opacity: 1;
}

.liLink:hover a:before {
  margin-right: 10px;
  opacity: 1;
}

.linkFooter {
  color: $greyMiddle;
}

.linkFooter:hover {
  color: $dominante;
}

/* ========================================================================== */
/*                                PAGES LEGALES                               */
/* ========================================================================== */

#cgvText {
  text-align: justify;
}

#mlText {
  text-align: justify;
}

/* ========================================================================== */
/*                                    ADMIN                                   */
/* ========================================================================== */

.signin {
  padding-top: 100px;
}

.deleteFolioBtn {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  background-color: $red;
}

.deleteFolioBtn:hover {
  background-color: $greyMiddle;
}

.deleteSkillBtn {
  margin-bottom: 100px;
  background-color: $red;
}

.saveFolioBtn {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
  background-color: $dominante;
}

.saveFolioBtn:hover {
  background-color: $greyMiddle;
}

.logOutBtn {
  color: $greyLight;
  background-color: $red;
  border: none;
}

.logOutBtn:hover {
  cursor: pointer;
}

.addBtn {
  background-color: $dominante;
}

/* ========================================================================== */
/*                                   CONTACT                                  */
/* ========================================================================== */

.contact {
  padding-top: 15px;
  text-align: center;
}

.btn-large {
  background-color: $dominante;
}

.btn-large:hover {
  background-color: $greyDark;
}

.prefix.active {
  color: $dominante !important;
}

.inputContact {
  border-bottom: 1px solid $dominante !important;
}

.inputContact:focus {
  border-bottom: 1px solid $greyLight !important;
}

.helperContact.active {
  color: $dominante !important;
}

/* ========================================================================== */
/*                                SOCIAL ICONS                                */
/* ========================================================================== */

#socialIcons {
  height: 30px;
  position: fixed;
  bottom: 30px;
  right: 0px;
  z-index: 12;
  max-width: 30px;

}

.socialIconLink {
  padding-left: 3px;
  padding-right: 3px;
  line-height: 25px;
  .socialIcon {
    transition: all 300ms;
    font-size: 1.6em;
  }
}

.socialIconLink:hover {
  .socialIcon {
    transition: all 300ms;
    transform: rotate(360deg);
    color: $greyLight;
  }
}